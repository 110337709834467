import React from "react";
import { graphql } from "gatsby";

import Page from "../../templates/Page";

const ContractPageFel = ({ data }) => {
  return <Page data={data} />;
};

export const pageQuery = graphql`
  query {
    page: markdownRemark(frontmatter: { path: { eq: "/fel-i-entreprenader" } }) {
      ...pageFragment
    }
  }
`;

export default ContractPageFel;
